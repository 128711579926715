import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <p>{`This guide will go through important concepts and the overall model of Rolling stock to better understand and make use
of this service.`}</p>
    <p>{`For simplicities sake, trains are used as a reference to easier understand the concepts.
Though this example is not limited by trains and can be expanded to the case of a bus as an example.`}</p>
    <p><img alt="ER Model" src={require("./ER-model.png")} /></p>
    <h2 {...{
      "id": "physical-structure"
    }}>{`Physical structure`}</h2>
    <p>{`The physical structure describes a carriage visually for a given operator and the eventual modifications they have made.
In practice it is usually the same or based on a litra for norwegian trains.`}</p>
    <h3 {...{
      "id": "basic-element"
    }}>{`Basic Element`}</h3>
    <p>{`A basic element describes a physical carriage. For example the adaption of litra A7-1 by VY.`}</p>
    <p>{`A basic element is identified by series and version. The series is assigned by the user. The version is set to 1
when the element is created. The version is bumped when new versions with the same series are created.`}</p>
    <h3 {...{
      "id": "basic-element-properties"
    }}>{`Basic Element Properties`}</h3>
    <p>{`Basic element properties describe general properties of the carriage. For example if there is wifi or a wheelchair lift.`}</p>
    <h3 {...{
      "id": "basic-seat"
    }}>{`Basic Seat`}</h3>
    <p>{`A basic seat is a seat in a basic element. It may also used for other objects like space for a standing person or a bicycle.`}</p>
    <h3 {...{
      "id": "basic-seat-properties"
    }}>{`Basic Seat Properties`}</h3>
    <p>{`Basic seat properties describe the fundamental properties of a basic seat. This should not be used to determine usage properties.`}</p>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <h4 {...{
      "id": "get-basic-element-by-id"
    }}>{`Get basic element by id.`}</h4>
    <p>{`The basic element structure is fetched based on database id.`}</p>
    <ExpandablePanel title="/v1-beta/basic-elements/:id" mdxType="ExpandablePanel">
    Example response GET /v1-beta/basic-elements/1
    <pre>
        {`{
  "id" : 1,
  "series" : "GOA-BM73A",
  "version" : 1,
  "name" : "Bm 73a",
  "status" : "DRAFT",
  "organisationId" : 20,
  "seats" : [ {
    "id" : 1,
    "number" : "S1",
    "x" : 2,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 2,
    "number" : "S2",
    "x" : 3,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 3,
    "number" : "S3",
    "x" : 4,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 4,
    "number" : "S4",
    "x" : 5,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 5,
    "number" : "S5",
    "x" : 6,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 6,
    "number" : "S21",
    "x" : 7,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 7,
    "number" : "S22",
    "x" : 8,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 8,
    "number" : "S23",
    "x" : 9,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  }, {
    "id" : 9,
    "number" : "S24",
    "x" : 10,
    "y" : 2,
    "properties" : {
      "accommodationFacility" : "RECLINING_SEATS",
      "seatAttachment" : "FOLDING",
      "seatPosition" : "AISLE",
      "relativeDirection" : "BACKWARDS",
      "baggageType" : [ "ANIMAL", "BICYCLE" ]
    }
  } ],
  "properties" : {
    "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
    "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
    "sanitaryFacilities" : [ "BABY_CHANGE", "OTHER" ],
    "mobilityFacilities" : [ "UNACCOMPANIED_MINOR_ASSISTANCE", "TACTILE_GUIDING_STRIPS" ],
    "baggageType" : [ "BICYCLE", "HANDBAG" ]
  }
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-basic-elements-by-search-criteria"
    }}>{`Get basic elements by search criteria.`}</h4>
    <p>{`A list of basic elements are fetched based on search criteria.`}</p>
    <ul>
      <li parentName="ul">{`series: The series specified when the element was created. Example: GOA-BM73A`}</li>
      <li parentName="ul">{`version: The version assigned when the element was created. Example: 2`}</li>
      <li parentName="ul">{`status: The status of the element: Example: ACTIVE`}</li>
      <li parentName="ul">{`name: The name of the element. Used by substring match. Example: GOA`}</li>
      <li parentName="ul">{`limit: The maximum number of elements fetched. example: 10`}</li>
      <li parentName="ul">{`page: The page number if more than `}<inlineCode parentName="li">{`limit`}</inlineCode>{` elements are fetched. Example: 5`}</li>
      <li parentName="ul">{`sort: Sorting strategy. Zero or more may be specified. Example: NAME_DESCENDING`}</li>
    </ul>
    <ExpandablePanel title="/v1-beta/basic-elements?series=:series&version=:version&status=:status&name=:name&limit:limit&page=:page&sort=:sort" mdxType="ExpandablePanel">
    Example response GET /v1-beta/basic-elements/?status=DRAFT&sort=VERSION_DESCENDING&sort=NAME_DESCENDING
    <pre>
        {`{
  "basicElements" : [ {
    "id" : 1,
    "series" : "GOA-BM73A",
    "version" : 1,
    "name" : "Bm 73a",
    "status" : "DRAFT",
    "organisationId" : 20,
    "seats" : [ {
      "id" : 1,
      "number" : "S1",
      "x" : 2,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 2,
      "number" : "S2",
      "x" : 3,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 3,
      "number" : "S3",
      "x" : 4,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 4,
      "number" : "S4",
      "x" : 5,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 5,
      "number" : "S5",
      "x" : 6,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 6,
      "number" : "S21",
      "x" : 7,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 7,
      "number" : "S22",
      "x" : 8,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 8,
      "number" : "S23",
      "x" : 9,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    }, {
      "id" : 9,
      "number" : "S24",
      "x" : 10,
      "y" : 2,
      "properties" : {
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "baggageType" : [ "ANIMAL", "BICYCLE" ]
      }
    } ],
    "properties" : {
      "nuisanceFacilities" : [ "BREASTFEEDING_FRIENDLY", "MOBILE_PHONE_FREE_ZONE" ],
      "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
      "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
      "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
      "baggageType" : [ "BICYCLE", "HANDBAG" ]
    }
  } ],
  "numberTotal" : 1
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-basic-element"
    }}>{`Create basic element.`}</h4>
    <p>{`A new basic element is created. The new element will have the given series and the next version.`}</p>
    <ExpandablePanel title="/v1-beta/basic-elements" mdxType="ExpandablePanel">
    Example request POST /v1-beta/basic-elements
    <pre>
        {`{
  "series": "GOA-BM73A",
  "name": "Bm 73a",
  "seats": [
    {
      "number": "S1",
      "x": 2,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S2",
      "x": 3,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S3",
      "x": 4,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S4",
      "x": 5,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S5",
      "x": 6,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S21",
      "x": 7,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S22",
      "x": 8,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S23",
      "x": 9,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    },
    {
      "number": "S24",
      "x": 10,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "ANIMAL",
          "BICYCLE"
        ]
      }
    }
  ],
  "properties": {
    "nuisanceFacilities": [
      "MOBILE_PHONE_FREE_ZONE",
      "BREASTFEEDING_FRIENDLY"
    ],
    "passengerCommsFacilities": [
      "INTERNET",
      "FREE_WIFI"
    ],
    "sanitaryFacilities": [
      "BABY_CHANGE",
      "OTHER"
    ],
    "mobilityFacilities": [
      "TACTILE_GUIDING_STRIPS",
      "UNACCOMPANIED_MINOR_ASSISTANCE"
    ],
    "baggageType": [
      "BICYCLE",
      "HANDBAG"
    ]
  }
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "update-basic-element"
    }}>{`Update basic element.`}</h4>
    <p>{`An element with the specified database id will be updated.`}</p>
    <ExpandablePanel title="/v1-beta/basic-elements/:id" mdxType="ExpandablePanel">
    Example request PUT /v1-beta/basic-elements/123
    <pre>
        {`{
  "status": "ACTIVE",
  "name": "Bm 73a lokal",
  "seats": [
    {
      "id": 1,
      "number": "S1",
      "x": 2,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 2,
      "number": "S2",
      "x": 3,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 3,
      "number": "S3",
      "x": 4,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 4,
      "number": "S4",
      "x": 5,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 5,
      "number": "S5",
      "x": 6,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 6,
      "number": "S21",
      "x": 7,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 7,
      "number": "S22",
      "x": 8,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 8,
      "number": "S23",
      "x": 9,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    },
    {
      "id": 9,
      "number": "S24",
      "x": 10,
      "y": 2,
      "properties": {
        "accommodationFacility": "RECLINING_SEATS",
        "seatAttachment": "FOLDING",
        "seatPosition": "AISLE",
        "relativeDirection": "BACKWARDS",
        "baggageType": [
          "BICYCLE",
          "ANIMAL"
        ]
      }
    }
  ],
  "properties": {
    "nuisanceFacilities": [
      "MOBILE_PHONE_FREE_ZONE",
      "BREASTFEEDING_FRIENDLY"
    ],
    "passengerCommsFacilities": [
      "INTERNET",
      "FREE_WIFI"
    ],
    "sanitaryFacilities": [
      "BABY_CHANGE",
      "OTHER"
    ],
    "mobilityFacilities": [
      "TACTILE_GUIDING_STRIPS",
      "UNACCOMPANIED_MINOR_ASSISTANCE"
    ],
    "baggageType": [
      "HANDBAG",
      "BICYCLE"
    ]
  }
}
`}
      </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "applied-structure"
    }}>{`Applied structure`}</h2>
    <p>{`The applied structure refines an occurrence of the physical structure by adding properties that determines its application.
This could for example be the operators own version of litra A7-1 as it is being used day trains on the Dovre line.`}</p>
    <h3 {...{
      "id": "logical-element"
    }}>{`Logical Element`}</h3>
    <p>{`A logical element describes a basic element as it would be used in a given application.
For example be the operators own version of litra A7-1 as it is being used day trains on the Dovre line.
The accompanying basic element is identified by database id or the series/version.`}</p>
    <p>{`A logical element is identified by series and version. The series is assigned by the user. The version is set to 1
when the element is created. The version is bumped when new versions with the same series are created.`}</p>
    <h3 {...{
      "id": "logical-seat-group"
    }}>{`Logical Seat Group`}</h3>
    <p>{`A logical seat group describes a logical compartment. This is used to give a more detailed management of automatic seat allocation.
It could for example be desirable that some products manage automatic seat allocation that are closer to eachter than other products.
The case of premium seats, travelers are grouped into four, but on standard seats it's sufficient enough that the travelers are allocated to the same compartment.
Logical seat groups can be nested.`}</p>
    <h3 {...{
      "id": "logical-seat"
    }}>{`Logical Seat`}</h3>
    <p>{`A logical seat is based on a basic seat with added properties that specify its appication.`}</p>
    <h3 {...{
      "id": "logical-seat-properties"
    }}>{`Logical Seat Properties`}</h3>
    <p>{`Logical seat properties determines properties of a seat for a given application.`}</p>
    <h3 {...{
      "id": "api-1"
    }}>{`API`}</h3>
    <h4 {...{
      "id": "get-logical-element-by-id"
    }}>{`Get logical element by id.`}</h4>
    <p>{`The logical element structure is fetched based on database id.`}</p>
    <ExpandablePanel title="/v1-beta/logical-elements/:id" mdxType="ExpandablePanel">
    Example response GET /v1-beta/logical-elements/1
    <pre>
        {`{
  "id" : 1,
  "series" : "GOA-BM73A-KOMFORT",
  "version" : 1,
  "name" : "Bm 73a komfort",
  "status" : "DRAFT",
  "organisationId" : 20,
  "idBasic" : 1,
  "nameBasic" : "Bm 73a",
  "seatGroups" : [ {
    "id" : 1,
    "name" : "Kupe 1",
    "seatGroups" : [ ],
    "seats" : [ {
      "id" : 1,
      "number" : "S1",
      "idBasic" : 1,
      "numberBasic" : "S1",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 2,
      "number" : "S2",
      "idBasic" : 2,
      "numberBasic" : "S2",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 3,
      "number" : "S3",
      "idBasic" : 3,
      "numberBasic" : "S3",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 4,
      "number" : "S4",
      "idBasic" : 4,
      "numberBasic" : "S4",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 5,
      "number" : "S5",
      "idBasic" : 5,
      "numberBasic" : "S5",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 6,
      "number" : "S21",
      "idBasic" : 6,
      "numberBasic" : "S21",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 7,
      "number" : "S22",
      "idBasic" : 7,
      "numberBasic" : "S22",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 8,
      "number" : "S23",
      "idBasic" : 8,
      "numberBasic" : "S23",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 9,
      "number" : "S24",
      "idBasic" : 9,
      "numberBasic" : "S24",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    } ]
  } ]
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-logical-element-by-series-and-version"
    }}>{`Get logical element by series and version.`}</h4>
    <p>{`The logical element structure is fetched based on series and version.`}</p>
    <ExpandablePanel title="/v1-beta/logical-elements?series=:series&version=:version" mdxType="ExpandablePanel">
    Example response GET /v1-beta/logical-elements/series=GOA-BM73A-KOMFORT&version=1
    <pre>
        {`{
  "id" : 1,
  "series" : "GOA-BM73A-KOMFORT",
  "version" : 1,
  "name" : "Bm 73a komfort",
  "status" : "DRAFT",
  "organisationId" : 20,
  "idBasic" : 1,
  "nameBasic" : "Bm 73a",
  "seatGroups" : [ {
    "id" : 1,
    "name" : "Kupe 1",
    "seatGroups" : [ ],
    "seats" : [ {
      "id" : 1,
      "number" : "S1",
      "idBasic" : 1,
      "numberBasic" : "S1",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 2,
      "number" : "S2",
      "idBasic" : 2,
      "numberBasic" : "S2",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 3,
      "number" : "S3",
      "idBasic" : 3,
      "numberBasic" : "S3",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 4,
      "number" : "S4",
      "idBasic" : 4,
      "numberBasic" : "S4",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 5,
      "number" : "S5",
      "idBasic" : 5,
      "numberBasic" : "S5",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 6,
      "number" : "S61",
      "idBasic" : 6,
      "numberBasic" : "S21",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 7,
      "number" : "S22",
      "idBasic" : 7,
      "numberBasic" : "S22",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 8,
      "number" : "S23",
      "idBasic" : 8,
      "numberBasic" : "S23",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    }, {
      "id" : 9,
      "number" : "S24",
      "idBasic" : 9,
      "numberBasic" : "S24",
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "nuisanceFacilities" : [ ],
        "baggageType" : [ ]
      }
    } ]
  } ]
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-all-logical-elements-by-basic-element-id"
    }}>{`Get all logical elements by basic element id.`}</h4>
    <p>{`All logical elements based on a specified basic element are fetched. The basic element is specified by database id.`}</p>
    <ExpandablePanel title="/v1-beta/logical-elements/basicElementId/:basicElementId" mdxType="ExpandablePanel">
    Example response GET /v1-beta/logical-elements/basicElementId/1
    <pre>
        {`{
  "logicalElements" : [ {
    "id" : 1,
    "series" : "GOA-BM73A-KOMFORT",
    "version" : 1,
    "name" : "Bm 73a komfort",
    "status" : "DRAFT",
    "organisationId" : 20,
    "idBasic" : 1,
    "nameBasic" : "Bm 73a",
    "seatGroups" : [ {
      "id" : 1,
      "name" : "Kupe 1",
      "seatGroups" : [ ],
      "seats" : [ {
        "id" : 1,
        "number" : "S1",
        "idBasic" : 1,
        "numberBasic" : "S1",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 2,
        "number" : "S2",
        "idBasic" : 2,
        "numberBasic" : "S2",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 3,
        "number" : "S3",
        "idBasic" : 3,
        "numberBasic" : "S3",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 4,
        "number" : "S4",
        "idBasic" : 4,
        "numberBasic" : "S4",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 5,
        "number" : "S5",
        "idBasic" : 5,
        "numberBasic" : "S5",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 6,
        "number" : "S21",
        "idBasic" : 6,
        "numberBasic" : "S21",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 7,
        "number" : "S22",
        "idBasic" : 7,
        "numberBasic" : "S22",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 8,
        "number" : "S23",
        "idBasic" : 8,
        "numberBasic" : "S23",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      }, {
        "id" : 9,
        "number" : "S24",
        "idBasic" : 9,
        "numberBasic" : "24",
        "properties" : {
          "fareClass" : "BUSINESS_CLASS",
          "groupBooking" : "GROUPS_ALLOWED",
          "nuisanceFacilities" : [ ],
          "baggageType" : [ ]
        }
      } ]
    } ]
  } ]
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-logical-element"
    }}>{`Create logical element.`}</h4>
    <p>{`A new logical element is created, based on a specified basic element. The new element will have the given series and the next version.`}</p>
    <ExpandablePanel title="/v1-beta/logical-elements" mdxType="ExpandablePanel">
    Example request POST /v1-beta/logical-elements
    <pre>
        {`{
  "series": "GOA-BM73A-KOMFORT",
  "name": "Bm 73a komfort",
  "idBasicElement": 1,
  "seriesBasicElement": "GOA-BM73A",
  "versionBasicElement": 1,
  "seatGroups": [
    {
      "name": "Kupe 1",
      "seats": [
        {
          "idBasicSeat": 1,
          "numberBasicSeat": "S1",
          "number": "S1",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 2,
          "numberBasicSeat": "S2",
          "number": "S2",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 3,
          "numberBasicSeat": "S3",
          "number": "S3",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 4,
          "numberBasicSeat": "S4",
          "number": "S4",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 5,
          "numberBasicSeat": "S5",
          "number": "S5",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 6,
          "numberBasicSeat": "S6",
          "number": "S6",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 7,
          "numberBasicSeat": "S20",
          "number": "S20",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 8,
          "numberBasicSeat": "S21",
          "number": "S21",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "idBasicSeat": 9,
          "numberBasicSeat": "S22",
          "number": "S22",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        }
      ],
      "seatGroups": []
    }
  ]
}
`}
      </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "update-logical-element"
    }}>{`Update logical element.`}</h4>
    <p>{`A logical element is updated.`}</p>
    <ExpandablePanel title="/v1-beta/logical-elements/:id" mdxType="ExpandablePanel">
    Example request PUT /v1-beta/logical-elements/123
    <pre>
        {`{
  "name": "Bm 73a komfort lang",
  "status": "ACTIVE",
  "seatGroups": [
    {
      "id": 1,
      "name": "Kupe første klasse",
      "seats": [
        {
          "id": 1,
          "idBasicSeat": 1,
          "numberBasicSeat": "S1",
          "number": "S101",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 2,
          "idBasicSeat": 2,
          "numberBasicSeat": "S2",
          "number": "S102",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 3,
          "idBasicSeat": 3,
          "numberBasicSeat": "S3",
          "number": "S103",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 4,
          "idBasicSeat": 4,
          "numberBasicSeat": "S4",
          "number": "S104",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 5,
          "idBasicSeat": 5,
          "numberBasicSeat": "S5",
          "number": "S105",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 6,
          "idBasicSeat": 6,
          "numberBasicSeat": "S21",
          "number": "S121",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 7,
          "idBasicSeat": 7,
          "numberBasicSeat": "S22",
          "number": "S122",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 8,
          "idBasicSeat": 8,
          "numberBasicSeat": "S23",
          "number": "S123",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        },
        {
          "id": 9,
          "idBasicSeat": 9,
          "numberBasicSeat": "S24",
          "number": "S124",
          "properties": {
            "fareClass": "BUSINESS_CLASS",
            "groupBooking": "GROUPS_ALLOWED",
            "nuisanceFacilities": [],
            "baggageType": []
          }
        }
      ],
      "seatGroups": []
    }
  ]
}
`}
      </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "consolidated-logical-element"
    }}>{`Consolidated logical element`}</h2>
    <p>{`A consolidated logical element contains the seats from a logical element. Each such seats will have a combination of basic element properties,
basic seat properties and basic element properties.`}</p>
    <p>{`Properties that have a single value do not overlap. They will be picked from the corresponding value in one of the property sets.`}</p>
    <p>{`Properties that have a set of values, will contain the sum of the corresponding values in all the property sets.`}</p>
    <ul>
      <li parentName="ul">{`fareClass. From logical seat properties.`}</li>
      <li parentName="ul">{`groupBooking. From logical seat properties.`}</li>
      <li parentName="ul">{`accommodationFacility. From basic seat properties.`}</li>
      <li parentName="ul">{`seatAttachment. From basic seat properties.`}</li>
      <li parentName="ul">{`seatPosition. From basic seat properties.`}</li>
      <li parentName="ul">{`relativeDirection. From basic seat properties.`}</li>
      <li parentName="ul">{`nuisanceFacilities. Sum of values from basic element properties and logical seat properties.`}</li>
      <li parentName="ul">{`passengerCommsFacilities: From basic element properties.`}</li>
      <li parentName="ul">{`sanitaryFacilities: From basic element properties.`}</li>
      <li parentName="ul">{`mobilityFacilities: From basic element properties`}</li>
      <li parentName="ul">{`baggageType. Sum of values from  basic element properties, basic seat properties and logical seat properties.`}</li>
    </ul>
    <h4 {...{
      "id": "get-consolidated-logical-element-by-id"
    }}>{`Get consolidated logical element by id.`}</h4>
    <p>{`Fetch consolidated element structure from the database id of the logical element.`}</p>
    <ExpandablePanel title="/v1-beta/consolidated-logical-elements" mdxType="ExpandablePanel">
    Example response GET /v1-beta/consolidated-logical-elements/1
    <pre>
        {`{
  "id" : 1,
  "series" : "GOA-BM73A-KOMFORT",
  "version" : 1,
  "name" : "Bm 73a komfort",
  "status" : "DRAFT",
  "organisationId" : 20,
  "seatGroups" : [ {
    "id" : 1,
    "name" : "Kupe 1",
    "seatGroups" : [ ],
    "seats" : [ {
      "id" : 1,
      "number" : "S1",
      "x" : 2,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 2,
      "number" : "S2",
      "x" : 3,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 3,
      "number" : "S3",
      "x" : 4,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 4,
      "number" : "S4",
      "x" : 5,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 5,
      "number" : "S5",
      "x" : 6,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 6,
      "number" : "S21",
      "x" : 7,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 7,
      "number" : "S22",
      "x" : 8,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 8,
      "number" : "S23",
      "x" : 9,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 9,
      "number" : "S24",
      "x" : 10,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    } ]
  } ]
}
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "get-consolidated-logical-element-by-series-and-version"
    }}>{`Get consolidated logical element by series and version.`}</h3>
    <p>{`Fetch consolidated element structure from the series and version of the logical element.`}</p>
    <h4 {...{
      "id": "api-2"
    }}>{`API`}</h4>
    <ExpandablePanel title="/v1-beta/consolidated-logical-elements?series=:series&version=:version" mdxType="ExpandablePanel">
    Example response GET /v1-beta/consolidated-logical-elements?series=GOA-BM73A-KOMFORT?version=1
    <pre>
        {`{
  "id" : 1,
  "series" : "GOA-BM73A-KOMFORT",
  "version" : 1,
  "name" : "Bm 73a komfort",
  "status" : "DRAFT",
  "organisationId" : 20,
  "seatGroups" : [ {
    "id" : 1,
    "name" : "Kupe 1",
    "seatGroups" : [ ],
    "seats" : [ {
      "id" : 1,
      "number" : "S1",
      "x" : 2,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 2,
      "number" : "S2",
      "x" : 3,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 3,
      "number" : "S3",
      "x" : 4,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 4,
      "number" : "S4",
      "x" : 5,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 5,
      "number" : "S5",
      "x" : 6,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 6,
      "number" : "S21",
      "x" : 7,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 7,
      "number" : "S22",
      "x" : 8,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 8,
      "number" : "S23",
      "x" : 9,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    }, {
      "id" : 9,
      "number" : "S24",
      "x" : 10,
      "y" : 2,
      "properties" : {
        "fareClass" : "BUSINESS_CLASS",
        "groupBooking" : "GROUPS_ALLOWED",
        "accommodationFacility" : "RECLINING_SEATS",
        "seatAttachment" : "FOLDING",
        "seatPosition" : "AISLE",
        "relativeDirection" : "BACKWARDS",
        "nuisanceFacilities" : [ "MOBILE_PHONE_FREE_ZONE", "BREASTFEEDING_FRIENDLY" ],
        "passengerCommsFacilities" : [ "FREE_WIFI", "INTERNET" ],
        "sanitaryFacilities" : [ "OTHER", "BABY_CHANGE" ],
        "mobilityFacilities" : [ "TACTILE_GUIDING_STRIPS", "UNACCOMPANIED_MINOR_ASSISTANCE" ],
        "baggageType" : [ "BICYCLE", "ANIMAL", "HANDBAG" ]
      }
    } ]
  } ]
}
`}
      </pre>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      